// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-exhibitions-tsx": () => import("./../../../src/pages/exhibitions.tsx" /* webpackChunkName: "component---src-pages-exhibitions-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-commissions-js": () => import("./../../../src/pages/work/commissions.js" /* webpackChunkName: "component---src-pages-work-commissions-js" */),
  "component---src-pages-work-eastern-js": () => import("./../../../src/pages/work/eastern.js" /* webpackChunkName: "component---src-pages-work-eastern-js" */),
  "component---src-pages-work-foreign-js": () => import("./../../../src/pages/work/foreign.js" /* webpackChunkName: "component---src-pages-work-foreign-js" */),
  "component---src-pages-work-southwestern-js": () => import("./../../../src/pages/work/southwestern.js" /* webpackChunkName: "component---src-pages-work-southwestern-js" */),
  "component---src-pages-work-waterscapes-js": () => import("./../../../src/pages/work/waterscapes.js" /* webpackChunkName: "component---src-pages-work-waterscapes-js" */)
}

